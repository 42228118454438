import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [3],
		"/about": [4],
		"/admin/categorise": [~5],
		"/admin/categorise/[dataImportIdOrAll]": [~6],
		"/admin/ocr": [~7],
		"/admin/tools": [~8],
		"/app": [~9],
		"/app/claim/[invitationId=integer]": [~10],
		"/app/claim/[invitationId=integer]/[claimToken]": [~11],
		"/app/common": [~12],
		"/app/help": [13],
		"/app/ocr/[productId=integer]": [~14],
		"/app/paid/[productId=integer]": [~15],
		"/app/partg": [~16],
		"/app/partn": [~17],
		"/app/partn/new": [~18],
		"/app/reject/[invitationId=integer]/[claimToken]": [~19],
		"/app/report": [~20],
		"/app/report/new": [~21],
		"/app/settings": [~22],
		"/app/settings/payments": [~23],
		"/app/settings/payments/success": [~24],
		"/blog/affordable-forensic-accounting-family-law": [~25],
		"/blog/forensic-account-for-self-represented-family-law-litigants": [~26],
		"/categories": [~27],
		"/compliance": [28],
		"/dev/testimonial": [29],
		"/faq": [30],
		"/financial-statement-kit": [31],
		"/integration/code": [~32],
		"/leap/customtab": [~33,[2]],
		"/leap/documents": [~34,[2]],
		"/leap/partg": [~35,[2]],
		"/leap/partn": [~36,[2]],
		"/leap/report": [~37,[2]],
		"/leap/share": [~38,[2]],
		"/learn/partn": [39],
		"/learn/report": [40],
		"/legals": [41],
		"/login": [~42],
		"/login/badlink": [44],
		"/login/[token=uuid]": [~43],
		"/ocr": [45],
		"/privacy": [46],
		"/register": [~47],
		"/support": [48],
		"/support/smokeball": [49],
		"/survey/[type=productType]": [50]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';